<template>
  <div>
    <v-btn v-if="visible" :disabled="!enabled" color="grey darken-2" text @click="onClick">
      <v-icon class="ml-2">{{ icon }}</v-icon>
      <slot></slot> 
    </v-btn>
  </div>
</template>

<script>
  import { isCurrentRouteAnyOf } from '@/synapps/router/router.utils'
  import {mapState} from "vuex";
  import moduleEnabledMixin from '@/synapps/mixins/moduleEnabled'

  export default {
    props: {
      icon: {
        type: String,
        required: true
      },
      visibleForModules: {
        type: Array,
        required: true
      },
      enabledForRoutes: {
        type: Array,
        required: true
      }
    },
    mixins: [moduleEnabledMixin],
    computed: {
      ...mapState(['config']),

      /**
       * Determines which app configurations are showing the map component.
       * @returns {boolean}
       */
      visible() {
        // TODO: assuming any meetnet app implicitly has a map viewer, because that's what I seem to have observed.
        return this.isConfigLoaded() && this.isAnyModuleEnabled(...this.visibleForModules)
      },

      enabled() {
        return this.visible && !isCurrentRouteAnyOf(this.$route, this.enabledForRoutes);
      }
    },

    methods: {
      onClick() {
        this.$emit('click') 
      }
    } 
  }
</script>
