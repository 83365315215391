import axios from "axios"
import Vue from "vue";

const state = {
  organizations: [],
  organizationPermissions: [],
  organizationMembers: {},
  memberPermissions: [],
  orgMemberRoles: [],
  notificationFrequency: [],
  resourceTypes: [],
};

const getters = {
  organizations(state) {
    return state.organizations;
  },
  organizationPermissions(state) {
    return state.organizationPermissions;
  },
  organizationMembers(state) {
    return state.organizationMembers;
  },
  memberPermissions(state) {
    return state.memberPermissions;
  },
  orgMemberRoles(state) {
    return state.orgMemberRoles;
  },
  notificationFrequency(state) {
    return state.notificationFrequency;
  },
  resourceTypes(state) {
    return state.resourceTypes;
  },
};

const mutations = {
  setOrganizations(state, payload) {
    state.organizations = payload;
  },
  setOrganizationPermissions(state, payload) {
    state.organizationPermissions = payload;
  },
  setOrganizationMembers(state, payload) {
    Vue.set(state.organizationMembers, payload.orgID, payload.data);
  },
  setMemberPermissions(state, payload) {
    state.memberPermissions = payload;
  },
  setOrgMemberRoles(state, payload) {
    state.orgMemberRoles = payload;
  },
  setNotificationFrequency(state, payload) {
    state.notificationFrequency = payload;
  },
  setResourceTypes(state, payload) {
    state.resourceTypes = payload;
  },
};

const actions = {
  /* Organization */
  getOrganizationsPermissions(context) {
    axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/authorization/organization/listPermissions/`,
    })
      .then((response) => {
        context.commit("setOrganizationPermissions", response.data);
      })
      .catch((error) => {
        console.log("getOrganizations error", error);
      });
  },
  getOrganizations(context) {
    axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/authorization/organization/`,
    })
      .then((response) => {
        context.dispatch("getOrganizationsPermissions");
        context.commit("setOrganizations", response.data);
      })
      .catch((error) => {
        console.log("getOrganizations error", error);
      });
  },
  getOrganization(context, orgID) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/authorization/organization/${orgID}/`,
    });
  },
  getOrganizationMembers(context, orgID) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/authorization/organization/${orgID}/members/`,
    });
  },
  getMemberPermissions(context) {
    axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/authorization/members/listPermissions/`,
    }).then(response => {
      context.commit("setMemberPermissions", response.data);
    }).catch(error => {
      console.log("Error getMemberPermissions", error)
    })
  },
  getOrganizationNetworks(context, orgID) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/authorization/organization/${orgID}/networks/`,
    });
  },
  getNetworkMembers(context, netID) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/sensoren/measurementnetwork/${netID}/members/`,
    });
  },
  getNetworkExternalMembers(context, netID) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/sensoren/measurementnetwork/${netID}/external_members/`,
    });
  },
  getAllMember(context, memberID) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/authorization/members/${memberID}/`,
    });
  },
  changeMemberRole(context, payload) {
    return axios({
      method: "PATCH",
      data: payload.data,
      url: context.getters.meetnetBaseURL + payload.url,
    });
  },
  getOrgMemberRoles(context) {
    axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/authorization/roles/`,
    })
      .then((response) => {
        context.commit("setOrgMemberRoles", response.data);
      })
      .catch((error) => {
        console.log("setOrgMemberRoles error", error);
      });
  },
  changeExternalNetworkMemberRole(context, payload) {
    /* Change Network external member role */
    return axios({
      method: "PATCH",
      data: payload.data, // { role: "admin" },
      url: `${context.getters.meetnetAPIURL}/authorization/external_members/${payload.memberID}/change_role/`,
    });
  },
  internalizeExternalNetworkMember(context, memberID) {
    /* Make network external member an organization member */
    return axios({
      method: "POST",
      url: `${context.getters.meetnetAPIURL}/authorization/external_members/${memberID}/add_to_org/`,
    });
  },

  /* User */
  createMeetnetUser(context, payload) {
    /*
    payload: {
      orgID: 2
      data: {
        "email": "kobededecker@gmail.com",
        "password": "dummy1234",
        "cas_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"
      }
    }

    Add User to org: adding to cas and meetnet.
    CAREFUL! user is added to same plan as cas_token owner
    */
    return axios({
      method: "POST",
      data: payload.data,
      url: `${context.getters.meetnetAPIURL}/authorization/organization/${payload.orgID}/add_user/`,
    });
  },

  /* Network Group Settings */
  getEmailNotifications(context) {
    return axios({
      method: "GET",
      url: `${context.getters.meetnetAPIURL}/authorization/email_notification/`,
    });
  },
  editEmailNotification(context, payload) {
    return axios({
      method: "PATCH",
      data: payload,
      url: `${context.getters.meetnetAPIURL}/authorization/email_notification/${payload.id}/`,
    });
  },
  deleteEmailNotification(context, id) {
    return axios({
      method: "DELETE",
      url: `${context.getters.meetnetAPIURL}/authorization/email_notification/${id}/`,
    });
  },
  createEmailNotification(context, payload) {
    return axios({
      method: "POST",
      data: payload,
      url: `${context.getters.meetnetAPIURL}/authorization/email_notification/`,
    });
  },
  getResourceTypes(context) {
    axios({
      method: "GET",
      url: `${context.getters.meetnetAPIURL}/authorization/email_notification/list_resource_types`,
    })
      .then((response) => {
        context.commit(
          "setResourceTypes",
          response.data.map((el) => {
            return {
              value: el.resourcetype,
              text: `${el.verbose_name[0].toUpperCase()}${el.verbose_name.slice(
                1
              )}`,
            };
          })
        );
      })
      .catch((error) => {
        console.log("setResourceTypes error", error);
      });
  },
  getNotificationFrequency(context) {
    axios({
      method: "GET",
      url: `${context.getters.meetnetAPIURL}/authorization/email_notification/list_valid_notification_frequencies`,
    })
      .then((response) => {
        context.commit(
          "setNotificationFrequency",
          response.data.map((el) => {
            return {
              value: el,
              text: `${el[0].toUpperCase()}${el.slice(1)}`,
            };
          })
        );
      })
      .catch((error) => {
        console.log("setNotificationFrequency error", error);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
