// main.js
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure
import Vuetify from "vuetify/lib";
import Vue from "vue";
import customIcon from "@/shared/assets/customIcon.vue";
import { getI18n } from '../../shared/plugins/i18n.js';

Vue.use(Vuetify, {
  iconfont: "mdi"
});

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      custom: {
        // name of our custom icon
        component: customIcon // our custom component
      }
    }
  },
  lang: {
    // Hooks in vue-i18n into Vuetify, so the translations are managed as a single source of truth by vue-i18n.
    // See https://vuetifyjs.com/en/features/internationalization/#vue-i18n
    t(key, ...params) {
      return getI18n().t(key, params);
    }
  }
});
