import axios from "axios";
import router from "@/synapps/router/router";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import moment from "moment";
import { getToken } from "./utils.js";

var token = localStorage.getItem("jwt") || null;

function verify(token) {
  return axios.post(`${import.meta.env.VITE_CAS_URL}/api-token-verify/`, {
    token,
  });
}

async function refresh(token) {
  try {
    let {
      data: { token: newToken },
    } = await axios.post(`${import.meta.env.VITE_CAS_URL}/api-token-refresh/`, {
      token,
    });
    localStorage.setItem("jwt", newToken);
    let { exp } = jwt_decode(newToken);
    let timeout = moment().diff(
      moment(exp * 1000).subtract(30, "seconds"),
      "seconds"
    );
    setTimeout(() => refresh(newToken), Math.abs(timeout) * 1000);
  } catch (err) {
    router.push(
      `${import.meta.env.VITE_CAS_URL}/login?service=${window.location.href}`
    );
  }
}

export function getUserData() {
  let res = jwt_decode(getToken());
  return res.user;
}

export function setupApi(url) {
  let instance = axios.create({
    baseURL: url,
    headers:
      _.includes(url, "imdcapps") || _.includes(url, "localhost")
        ? {
            authorization: `Bearer ${getToken()}`,
          }
        : {},
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (401 === error.response?.status) {
        console.log(window.location.href);
        // if (_.includes(window.location.href, "localhost")) {
        //   alertify.prompt("Insert jwt token", (val) => {
        //     document.cookie = `jwt_synapps=${val}`;
        //     window.location.reload();
        //   });
        // } else {
        //   alertify.error(
        //     "You seem to have incorrect authorization credentials. We will redirect you to the login page in 3 seconds."
        //   );
        //   setTimeout(() => {
        //     window.location.replace(import.meta.env.VITE_CAS_URL + "/logout");
        //     return Promise.reject(error);
        //   }, 3000);
        // }
      } else {
        return Promise.reject(error);
      }
    }
  );

  return instance;
}

function toLogin() {
  window.location.replace(
    _.replace(
      `${import.meta.env.VITE_CAS_URL}/login?service=${window.location.href}`,
      "#/",
      ""
    )
  );
}

export function waitForAuth() {
  return new Promise(async (resolve) => {
    if (token) {
      try {
        await verify(token);
      } catch (error) {
        console.log(error);
        localStorage.removeItem("jwt");
        toLogin();
      }
    } else {
      let url = new URL(window.location.href);
      window.history.replaceState(null, null, window.location.pathname);

      let ticket = url.searchParams.get("ticket");
      if (ticket) {
        try {
          let { data: resp } = await axios.get(
            `${import.meta.env.VITE_CAS_URL}/serviceValidate?ticket=${ticket}&service=${window.location.href}`
          );
          let parser = new DOMParser();
          resp = parser.parseFromString(resp, "text/xml");
          token = resp.getElementsByTagName("cas:jwt_synapps")[0].innerHTML;
          localStorage.setItem("jwt", token);
        } catch (error) {
          console.log(error)
          window.location.replace(`${import.meta.env.VITE_CAS_URL}/logout`);
        }
      } else {
        toLogin();
      }
    }

    if (token) {
      const { exp } = jwt_decode(token);
      const timeout = moment().diff(
        moment(exp * 1000).subtract(30, "seconds"),
        "seconds"
      );

      setTimeout(() => {
        refresh(token);
      }, Math.abs(timeout) * 1000);
      resolve();
    }
  });
}
