<template>
  <div>
    <v-app-bar id="topBar" fixed dense elevation="3" color="white">
      <router-link to="/" data-cy="home-btn" style="margin: 10px">
        <img
          src="../../shared/assets/img/placeholder.png"
          height="30"
          :title="version"
          id="topBarLogo"
        />
      </router-link>
      <Keypress
        key-event="keyup"
        :key-code="72"
        :modifiers="['altKey']"
        @success="$router.push({ name: 'appView' })"
      ></Keypress>
      <Keypress
        v-if="$route.params.id"
        key-event="keyup"
        :key-code="67"
        :modifiers="['altKey']"
        @success="$router.replace({ path: `/config/${$route.params.id}` })"
      ></Keypress>
      <Keypress
        v-if="$route.params.id"
        key-event="keyup"
        :key-code="77"
        :modifiers="['altKey']"
        @success="$router.replace({ path: `/map/${$route.params.id}` })"
      ></Keypress>
      <v-toolbar-title id="topBarTitle">Sinapps <span id="topBarTitleClientName"></span> {{ manage(config.title) }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <map-top-bar-button
        icon="mdi-clipboard-list-outline"
        :visible-for-modules="['meetnet']"
        :enabled-for-routes="meetnetRoutes"
        @click="$router.push({ path: `/meetnet/${config.id}/` })"
        data-e2e="topbar-dashboard-button"
      >
        {{ $t('meetnet.top-bar-button-label') }}
      </map-top-bar-button>

      <map-top-bar-button 
        icon="mdi-map" 
        :visible-for-modules="['observations', 'staticLayer', 'meetnet']"
        :enabled-for-routes="mapRoutes"
        @click="$router.push({ name: 'map-view.by-id', params: {id: config.id} })"
        data-e2e="topbar-dashboard-button"
      >
        {{ $t('map.top-bar-button-label') }}
      </map-top-bar-button>

      <map-top-bar-button
        icon="mdi-chart-sankey"
        :visible-for-modules="['thresholdAnalysis']"
        :enabled-for-routes="thresholdAnalysisRoutes"
        @click="$router.push({ name: 'threshold-analysis.home', params: { id: config.id } })"
        data-e2e="topbar-dashboard-button"
      >
        {{ $t('threshold-analysis.top-bar-button-label') }}
      </map-top-bar-button>

      <map-top-bar-button
        icon="mdi-abacus"
        :visible-for-modules="['multicriteria']"
        :enabled-for-routes="multiCriteriaRoutes"
        @click="$router.push({ name: 'multicriteria.scores', params: {id: config.id} })"
        data-e2e="topbar-dashboard-button"
      >
        {{ $t('multicriteria.top-bar-button-label') }}
      </map-top-bar-button>

      <v-menu
        bottom
        min-width="150px"
        :close-delay="80"
        :offset-y="true"
        transition="scroll-y-reverse-transition"
        v-if="isAuthenticated"
      >
        <template v-slot:activator="{ on }">
          <v-btn text bottom v-on="on" data-cy="account-menu">
            <v-badge
              :value="taskBadge"
              dot
              overlap
              color="primary"
            >
              <v-avatar color="rgb(18, 219, 155)" size="28" v-if="userData.username">
                <span class="white--text">{{ userData.username[0] }}</span>
              </v-avatar>
              <v-icon v-else style="color: rgb(18, 219, 155)">mdi-account-circle</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list-item-group color="primary">
          <v-list dense>
              <div class="font-weight-bold text-capitalize ml-3">{{ userData.username }}</div>
              <div class="my-2 ml-3">{{ userData.email }}</div>
            <v-divider></v-divider>
            <v-list-item>{{ $t("other.My Apps") }}</v-list-item>
            <v-list-item @click="$router.push({ name: 'taskSettings' })" v-if="isConfigLoaded() && isModuleEnabled('meetnet')">
              <v-badge
                :value="taskBadge"
                dot
                color="primary"
              >
                {{ $t('other.My Tasks') }}
              </v-badge>
            </v-list-item>
            <v-list-item @click="$router.push({ name: 'accountSettings' })" data-cy="settings-menu">{{ $t("common.Settings") }}</v-list-item>
            <v-list-item @click="showLoc" v-if="showLocationIfMap"
              ><v-switch
                dense
                @click.stop
                v-model="locationToggle"
                :label="$t('location')"
              ></v-switch
            ></v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="logout()">{{
              $t("common.Logout")
            }}</v-list-item>
          </v-list>
        </v-list-item-group>
      </v-menu>

      <v-menu
        bottom
        left
        open-on-hover
        :close-delay="80"
        :offset-y="true"
        transition="scroll-y-reverse-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon bottom v-on="on" data-cy="lang-btn">
            <v-icon style="color: rgb(18, 155, 219)">mdi-flag</v-icon>
          </v-btn>
        </template>
        <v-list-item-group color="primary">
          <v-list>
            <v-list-item v-for="(lang, i) in languages" :key="i" @click="setLanguage(lang)">
              <v-list-item-content small depressed>{{
                lang
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-item-group>
      </v-menu>
    </v-app-bar>
    <v-dialog v-model="changePasswordToggle" width="400">
      <v-card
        ><v-card-title class="text-h5 primary lighten-2"
          >Change password for user {{ userData.username }}</v-card-title
        >
        <v-card-text>
          <v-form
            @submit.prevent="
              $refs.passwordForm.validate() && passwordChangeSubmit()
            "
            ref="passwordForm"
          >
            <v-text-field
              type="password"
              :label="$t('old-password')"
              v-model="passwordDict.old_password"
              autofocus
            >
            </v-text-field>
            <v-text-field
              type="password"
              :label="$t('new-password')"
              v-model="passwordDict.new_password"
              :rules="[(x) => x.length >= 8 || 'minimum length 8 characters']"
            >
            </v-text-field>
            <v-text-field
              type="password"
              :label="$t('new-password') + ' (check)'"
              :rules="[
                (x) =>
                  x === passwordDict.new_password || `Passwords don't match`,
              ]"
            >
            </v-text-field>
            <v-btn
              type="submit"
              class="primary"
              :loading="passwordChangeLoading"
              >{{ $t("change-password") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-fade-transition>
              <v-alert
                border="left"
                color="red lighten-2"
                v-if="passwordChangeErrorMsg"
                >{{ passwordChangeErrorMsg }}</v-alert
              >
            </v-fade-transition>
          </v-form>
        </v-card-text></v-card
      >
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import "alertify.js/dist/css/alertify.css";
import {getToken} from "@/synapps/utils";
import axios from "axios";
import TemplateDialog from "@/synapps/components/multicriteria/chapter/templateDialog";
import MapTopBarButton from "@/synapps/components/topBarButtons/mapTopBarButton";
import moduleEnabledMixin from "@/synapps/mixins/moduleEnabled";
import mapRoutes from '@/synapps/router/mapRoutes'
import meetnetRoutes from '@/synapps/router/meetnetRoutes'
import { routes as thresholdAnalysisRoutes } from '@/synapps/threshold-analysis'
import multiCriteriaRoutes from '@/synapps/router/multicriteria'

export default {
  components: {
    MapTopBarButton,
    TemplateDialog
  },
  mixins: [moduleEnabledMixin],
  data: () => ({
    mapRoutes, meetnetRoutes, thresholdAnalysisRoutes, multiCriteriaRoutes,

    changePasswordToggle: false,
    passwordChangeErrorMsg: undefined,
    passwordChangeLoading: false,
    watchId: undefined,
    languages: ["NL", "EN", "FR", "DE", "ES"],
    userOptions: ["Profile", "Logout"],
    name: undefined,
    on: undefined,
    passwordDict: { old_password: "", new_password: "" },
    svgPath: "../../shared/assets/circuit.svg",
    dialogShare: false,
    shareUrl: "",
    locLayer: undefined,
  }),
  methods: {
    setLanguage(locale) {
      this.$store.commit('setLanguage', locale);

      // Change the locale of $vuetify so it can i18n the names of default labels like "Rows per page" and such.
      // TODO: KevinVR would prefer this to be done inside the store, but I can't figure out how to access $vuetify from there...
      this.$vuetify.lang.current = locale.toLowerCase();
    },
    async passwordChangeSubmit() {
      let token = getToken();
      this.passwordChangeLoading = true;
      try {
        await axios.put(
          `${import.meta.env.VITE_CAS_URL}/change_password`,
          this.passwordDict,
          { headers: { Authorization: `JWT ${token}` } }
        );
        this.changePasswordToggle = false;
        this.passwordDict = {
          old_password: "",
          new_password: "",
        };
      } catch (err) {
        this.passwordChangeErrorMsg = err.response.data || err;
      }
      this.passwordChangeLoading = false;
    },
    showLoc() {
      this.locLayer = this.$L.layerGroup([]);
      localStorage.setItem("synapps_loc", true);
      this.locLayer.addTo(this.map);
      this.watchId = navigator.geolocation.watchPosition(
        ({ coords }) => {
          try {
            this.locLayer.clearLayers();
          } catch (err) {}
          this.locLayer.addLayer(
            this.$L.circleMarker([coords.latitude, coords.longitude], {
              fillColor: "lightsteelblue",
              color: "darkblue",
              radius: 5,
              fillOpacity: 1,
              opacity: 1,
            })
          );
        },
        () => {}
      );
    },
    removeLoc() {
      localStorage.removeItem("synapps_loc");
      try {
        this.locLayer.clearLayers();
      } catch (err) {}
      navigator.geolocation.clearWatch(this.watchId);
    },
    manage(name) {
      return this.$route.name == "loginView"
        ? ""
        : "- " + (this.$_.isEmpty(name) ? this.$t("other.App overview") : name);
    },
    logout() {
      this.$store.commit("clearToken");
      // window.location.href = import.meta.env.VITE_CAS_URL + "/logout";
    },

    onSelectTidalToolLocation(event) {
      const options = event.target.querySelectorAll('option');
      this.$store.state.tidalTool.selectedLocationName = [...options.values()].find(o => o.selected).innerText;
    }
  },
  computed: {
    ...mapState(["config", "map", "userData", "taskBadge"]),
    ...mapGetters(["isAuthenticated", "showLocationOnMap"]),
    taskNotificationToggle() {
      console.log("taskNotificationToggle")
      this.$store.dispatch("toggleTaskNotification");
    },
    locationToggle: {
      get() {
        return this.showLocationOnMap;
      },
      set(newVal) {
        this.$store.dispatch("setShowLocationOnMap", newVal);
        if (newVal) {
          console.log("showing loc");
          this.showLoc();
        } else {
          console.log("removing loc");
          this.removeLoc();
        }
      }
    },
    currentRoute() {
      if (this.$route.path.includes("map")) {
        return "map";
      } else if (this.$route.path.includes("config")) {
        return "config";
      } else {
        return "app";
      }
    },
    showLocationIfMap() {
      return this.$route.path.includes("map");
    },
    version() {
      return import.meta.env.VITE_VERSION;
    }
  },
  watch: {
    passwordChangeErrorMsg(newval) {
      if (newval) {
        setTimeout(() => {
          this.passwordChangeErrorMsg = undefined;
        }, 5000);
      }
    },
    $route(newval) {
      if (this.showLocationOnMap && newval.name == "map-view.by-id") {
        setTimeout(() => {
            this.showLoc();
          }, 3000);
      }
    },
  },
  mounted() {
    if (this.showLocationOnMap) {
      this.showLoc();
    }
  },
};
</script>

<style>
#topBar {
  z-index: 10;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
