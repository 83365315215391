import axios from "axios";
import meetnetSiteStore from "@/shared/store/meetnet/measurementSite";
import meetnetSettings from "@/shared/store/meetnet/meetnetSettingsStore";
import meetnetSensorStore from "@/shared/store/meetnet/measurementSensor";
import _ from 'lodash';

const state = {
  meetnetDrawer: true,
  meetnetJWT: "",
  meetnetAuthPayload: "",
  meetnetBaseURL: "",
  sinappsAppID: "",
  meetnetLayerLastUrl: "",
  meetnetObjectLogs: {},
  objectLogActions: [],
};

const getters = {
  meetnetDrawer(state) {
    return state.meetnetDrawer;
  },
  meetnetJWT(state) {
    return state.meetnetJWT;
  },
  meetnetAuthPayload(state) {
    return state.meetnetAuthPayload;
  },
  meetnetBaseURL(state) {
    return state.meetnetBaseURL;
  },
  meetnetAPIURL(state) {
    return `${state.meetnetBaseURL}/api`;
  },
  meetnetLayerLastUrl(state) {
    return state.meetnetLayerLastUrl;
  },
  sinappsAppID(state) {
    return parseInt(state.sinappsAppID);
  },
  meetnetObjectLogs(state) {
    return state.meetnetObjectLogs;
  },
  objectLogActions(state) {
    return state.objectLogActions;
  }
};

const mutations = {
  setMeetnetDrawer(state, newValue) {
    state.meetnetDrawer = newValue;
  },
  setMeetnetJWT(state, token) {
    localStorage.setItem("meetnetJWT", token);
    state.meetnetJWT = token;
  },
  setMeetnetAuthPayload(state, payload) {
    localStorage.setItem("meetnetAuthPayload", JSON.stringify(payload));
    state.meetnetAuthPayload = payload;
  },
  setMeetnetBaseURL(state, baseURL) {
    localStorage.setItem("meetnetBaseURL", baseURL);
    state.meetnetBaseURL = baseURL;
  },
  setSinappsAppID(state, sinappsAppID) {
    state.sinappsAppID = parseInt(sinappsAppID);
  },
  setMeetnetLayerLastUrl(state, payload) {
    state.meetnetLayerLastUrl = payload;
  },
  setMeetnetObjectLogs(state, payload) {
    state.meetnetObjectLogs[payload.key] = payload.value;
  },
  setObjectLogActions(state, payload) {
    state.objectLogActions = payload;
  },
};

const actions = {
  authenticateMeetnet(context, baseURL) {
    let casJWT = localStorage.getItem("jwt");
    let url = `${baseURL}/api-token-auth`;

    context.commit("setMeetnetBaseURL", baseURL);

    return axios({
      method: "POST",
      withCredentials: true,
      headers: { Authorization: "jwt " + casJWT },
      url,
    });
  },

  fetchNetworkAndVariables(context, payload) {
    let axiosPromises = [];
    payload.measurementnetworks.forEach((oid) => {
      context.dispatch("fetchNetworkSiteVariables", oid);
      axiosPromises.push(context.dispatch("getMeasurementNetwork", oid));
    });

    axios.all(axiosPromises).then(
      axios.spread((...args) => {
        let measurementNetworks = [];
        for (let i = 0; i < args.length; i++) {
          measurementNetworks.push(args[i].data);
        }
        context.commit("setMeasurementNetworks", {
          data: measurementNetworks,
          sinappsAppID: payload.sinappsAppID,
        });
      })
    );
  },

  reducedSetupMeetnet(context, payload) {
    context.commit("setMeetnetAuthPayload", payload);
    context.commit("setSinappsAppID", payload.sinappsAppID);

    context
      .dispatch("authenticateMeetnet", payload.url)
      .then((response) => {
        context.commit("setMeetnetJWT", response.data.token);
      })
      .catch((error) => {
        console.log("authenticateMeetnet error", error);
      });
  },

  setupMeetnet(context, payload) {
    context.commit("setMeetnetAuthPayload", payload);
    context.commit("setSinappsAppID", payload.sinappsAppID);
    // set Measurement Network IDs
    context.commit("setMeasurementNetworkIDs", {
      data: payload.measurementnetworks,
      sinappsAppID: payload.sinappsAppID,
    });

    context
      .dispatch("authenticateMeetnet", payload.url)
      .then((response) => {
        context.commit("setMeetnetJWT", response.data.token);

        // fetch Networks and their Variables
        context.dispatch("fetchNetworkAndVariables", payload);
      })
      .catch((error) => {
        console.log("authenticateMeetnet error", error);
      });
  },

  setMeetnetJWT({ commit }, token) {
    commit("setMeetnetJWT", token);
  },
  setMeetnetDrawer({ commit }, newValue) {
    commit("setMeetnetDrawer", newValue);
  },
  setSinappsAppID({ commit }, sinappsAppID) {
    commit("setSinappsAppID", sinappsAppID);
  },
  setMeetnetLayerLastUrl({ commit }, payload) {
    commit("setMeetnetLayerLastUrl", payload);
  },
  getObjectLogs(context, { contentType, objectID, action, search }) {
    return axios({
      method: "GET",
      url: `${context.getters.meetnetAPIURL}/logger/objectlog/?`+
      _.join(
        _.remove([
          contentType ? `content_type=${contentType}` : "",
          objectID ? `object_id=${objectID}` : "",
          action ? `action_flag=${action}` : "",
          search ? `search=${search}` : "",
        ]),
        "&"
      ),
    });
  },
  getObjectLogActions(context) {
    axios({
      method: "GET",
      url: `${context.getters.meetnetAPIURL}/logger/objectlog/get_actions/`,
    }).then(response => {
      let actionsList = [];
      for (const [num, name] of Object.entries(response.data)) {
        actionsList.push({text: name, value: num})
      }
      context.commit("setObjectLogActions", actionsList);
    });
  },
};

const modules = {
  meetnetSiteStore,
  meetnetSensorStore,
  meetnetSettings,
};

export default {
  state,
  getters,
  mutations,
  actions,
  modules,
};
