export default [{
    path: "/map?scenarioId",
    name: "mapView",
    component: () => import("@/synapps/views/mapView"),
    meta: {requiresAuth: true},
}, {
    path: "/map/:id", // dynamic segments start with a colon
    component: () => import("@/synapps/views/mapView"),
    name: 'map-view.by-id',
    meta: {requiresAuth: true},
}];
