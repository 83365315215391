<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="555">
      <v-card class="mx-auto" style="min-height: 320px;" :class="reveal ? 'large' : ''">
        <v-card-title class="text-h5">
          {{ $t("templates") }}
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mx-2"
                fab
                v-bind="{...attrs, ...permissionViewStateBindProps('mca-tool.mca.add_template')}"
                v-on="on"
                x-small
                dark
                style="text-decoration: none"
                @click="newTemplateForm"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("new-template") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          {{
            $t(
              "select-or-create-a-template-for-your-kustvisie-weight-allocation"
            )
          }}

          <div v-if="mca_templates_loading">
            <v-progress-linear
              indeterminate
              :active="mca_templates_loading"
              class="mb-2"
            ></v-progress-linear>
          </div>

          <v-data-table
            :headers="templateHeaders"
            v-model="selected"
            :items="templates"
            :items-per-page="5"
            :disable-pagination="true"
            :hide-default-footer="true"
            item-key="oid"
            show-select
            :single-select="true"
            @item-selected="handleItemSelected"
          >
            <template v-slot:[`item.name`]="{ item }">
              <span class="text-capitalize">{{ item.name }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editTemplate(item)" v-if="!permissionViewStateBindProps('mca-tool.mca.change_template').disabled">mdi-pencil</v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">
                Reset
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="$_.isEmpty(selected)"
            color="green darken-1"
            text
            @click="$emit(`close`)"
          >
            {{ $t("meetnet.actions.close") }}
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <v-card
            v-if="reveal"
            class="transition-fast-in-fast-out v-card--reveal"
            style="height: 100%;"
          >
            <v-card-text class="pa-5">
              <h5 class="">{{ $t("new-template") }}</h5>
              <template-form
                @save="handleSaveTemplate"
                @cancel="reveal = false"
                :loading="createTemplateLoading"
              />
            </v-card-text>
          </v-card>
        </v-expand-transition>
        <template-weights-dialog
          :weightsDialog="weightsDialog"
          :template="dialogTemplate"
          :solution="solution"
          @closeDialog="handleCloseWeightsDialog"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import templateForm from "./templateForm.vue";
import TemplateWeightsDialog from "./templateWeightsDialog.vue";
import NewTemplateDialog from "./newTemplateDialog.vue";
export default {
  components: { TemplateWeightsDialog, templateForm },
  name: "TemplateDialog",
  emits: ["templateSelected", "refreshTemplates"],
  props: ["dialog", "solution", "templates", "selectedTemplate"],
  data() {
    return {
      reveal: false,
      loading: false,
      createTemplateLoading: false,
      // selected: [],

      // Template Weights Dialog
      dialogTemplate: {},
      weightsDialog: false,
      createTemplateDialog: false,

      templateHeaders: [
        {
          text: `${this.$t("meetnet.headers.name")}`,
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: `${this.$t("description")}`, value: "description" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["mca_templates_loading", 'hasPermission']),
    selected: {
      get: function() {
        return this.selectedTemplate;
      },
      set: function(val) {
        // console.log("Set selectedTemplate", val);
      },
    },
  },
  methods: {
    newTemplateForm() {
      console.log("newTemplateForm");
      this.reveal = true;
    },
    handleItemSelected(selection) {
      // console.log("handleItemSelected", selection);
      if (selection.value) {
        this.$emit("templateSelected", selection.item);
      }
    },
    handleSaveTemplate(template) {
      this.createTemplateLoading = true;
      template.analysis = this.solution.oid;

      this.$store
        .dispatch("mca_create_template", template)
        .then((response) => {
          this.$notification.success({
            message: `${this.$t("template-created-successfully")}!`,
          });

          this.dialogTemplate = response.data;
          this.createTemplateLoading = false;
          this.reveal = false;
          this.weightsDialog = true;

          this.$emit("refreshTemplates");
          console.log("mca_create_template", response.data);

          this.$emit("templateSelected", response.data);
        })
        .catch((error) => {
          let errorMsg = "";
          if (error?.response?.data) {
            for (const [prop, msg] of Object.entries(error.response.data)) {
              console.log("prop: ", prop, " msg:", msg);
              errorMsg += `${prop}: ${msg.reduce(
                (prev, curr) => `${prev ? prev + ", " : ""}${curr}`,
                ""
              )}`;
            }
          }
          this.createTemplateLoading = false;
          this.$notification.error({
            message: `${this.$t(
              "something-went-wrong-template-was-not-created"
            )}!`,
            description: errorMsg,
          });
        });
    },
    handleCloseWeightsDialog() {
      this.dialogTemplate = {};
      this.weightsDialog = false;
    },
    editTemplate(template) {
      console.log("editTemplate", template);
      this.dialogTemplate = template;
      this.weightsDialog = true;
    },
    deleteTemplate(template) {
      console.log("deleteTemplate", template);
    },

    permissionViewStateBindProps(permission) {
      const granted = this.hasPermission(permission)
      return {
        disabled: !granted,
      }
    }
  },
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.large {
  width: 555px;
  height: 320px;
}
</style>
