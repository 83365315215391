import Vue from "vue";
import Router from "vue-router";
import store from "@/synapps/store/store";
import meetnetRoutes from "./meetnetRoutes";
import settingsRoutes from "./settingsRoutes";
import multicriteriaRoutes from "./multicriteria";
import mapRoutes from './mapRoutes';
import {routes as thresholdAnalysisRoutes } from '@/synapps/threshold-analysis';
// import settingsRoutes from "./settings";

Vue.use(Router);

let router = new Router({
  // mode: "history",
  base: "/",
  routes: [
    {
      path: "/login/:nextUrl?",
      name: "loginView",
      component: () => import("@/synapps/views/loginView"),
    },
    { path: "/redirect" },
    {
      path: "/",
      name: "appView",
      component: () => import("@/synapps/views/appView"),
      meta: { requiresAuth: true },
    },
    ...mapRoutes,
    ...meetnetRoutes,
    ...multicriteriaRoutes,
    ...settingsRoutes,
    ...thresholdAnalysisRoutes,
    {
      path: "/config/:id",
      component: () => import("@/synapps/views/configView"),
      meta: { requiresAuth: true },
      props: true,
      children: [
        {
          name: "projectsettings",
          path: "", //should load default page but does nog work
          props: true,
          component: () => import("@/synapps/components/configuration/projectSettings"),
        },
        {
          name: "sharePage",
          path: "sharePage",
          props: true,
          component: () => import("@/synapps/components/configuration/sharePage"),
        },
        {
          name: "projectcontent",
          path: "content",
          props: true,
          component: () => import("@/synapps/components/configuration/projectContent"),
        },
      ],
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("@/synapps/views/about"),
    },
    { path: "*", redirect: "/" },
  ],
  // mode: 'abstract' THIS MAKES IT POSSIBLE TO HIDE THE URL ID BUT BEHAVIOUR IS UNPREDICTABLE
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      window.replace(
        `${import.meta.env.VITE_CAS_URL}/login?service=${window.location.href}`
      );
      // next({ name: "loginView", params: { nextUrl: to.fullPath } });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (to.matched.some((record) => record.meta.isAdmin)) {
        if (user.is_admin) {
          next();
        } else {
          next({ name: "appView" });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
