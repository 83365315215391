import _ from "lodash";

export function nextEl(arr, el) {
  let id = _.indexOf(arr, el);
  if (id == arr.length - 1) {
    return arr[0];
  } else {
    return arr[id + 1];
  }
}

export function previousEl(arr, el) {
  let id = _.indexOf(arr, el);
  if (id == 0) {
    return arr[arr.length - 1];
  } else {
    return arr[id - 1];
  }
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function getToken() {
  return localStorage.getItem("jwt") || getCookie("jwt_synapps");
}
