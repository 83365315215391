/**
 * Extracts all the names of the routes array provided, including child routes one layer deep.
 * @param routes[] Array of Vue route definitions.
 */
function routeNames(routes) {
    const names = [];
    routes.forEach(route => {
        if (route.name) {
            names.push(route.name);
        }

        if(route.children) {
            route.children.forEach(childRoute => {
                const { name } = childRoute;
                if(name) {
                    names.push(childRoute.name)
                }
            });
        }
    });
    return names;
}

/**
 * Checks if the current route is any of the supplied routes or subroutes.
 * @param currentRoute {object} The current route to check if it's part of the specified route array. Value of `$route` property.
 * @param routes[] The routes to check if the current route is part of it.
 * @returns {boolean} True if the current route is any of the specified routes
 */
export function isCurrentRouteAnyOf(currentRoute, routes) {
    return routeNames(routes).includes(currentRoute.name);
}
