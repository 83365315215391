/**
 * Determines if the config is loaded for the current app in the store or not.
 * Currently, this is done by checking the `title` property which is acting as a surrogate because all apps have a title.
 * @returns {boolean}
 */
function isConfigLoaded() {
    return !!this.config?.title;
}

/**
 * Checks if the module with the given name is enabled in the app config.
 * If the app config is not loaded, will return false.
 * @param {string} moduleName
 * @return `true` if the app config is found and the given module name is found in its configured modules.
 */
function isModuleEnabled(moduleName) {
    if (!moduleName) {
        throw new Error(`Argument 'moduleName' must be a string but was '${moduleName}'`);
    }

    if (!this.config?.modules) {
        return false;
    }

    return !!this.config.modules[moduleName] || !!this.config[moduleName];
}

function isAnyModuleEnabled(...moduleNames) {
    if (!Array.isArray(moduleNames)) {
        throw new Error(`Argument 'moduleNames' must be an array but was '${moduleNames}'`)
    }

    for (const moduleName of moduleNames) {
        if (this.isModuleEnabled(moduleName)) {
            console.debug(`Map button is visible because module '${moduleName}' is enabled in app config.`);
            return true;
        }
    }

    console.debug(`Map button is invisible because none of the modules ${moduleNames.join(', ')} are enabled in app config.`);
    return false;
}

export default {
    methods: {
        isConfigLoaded,
        isModuleEnabled,
        isAnyModuleEnabled
    }
}