import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as vuetifyLocales from 'vuetify/lib/locale';

let i18nInstance = null

Vue.use(VueI18n)

async function loadLocaleMessages () {
  const codes = ['en', 'de', 'es', 'fr', 'it', 'nl']
  const messages = {}

  await Promise.all(codes.map(async locale => {
    try {
      const module = await import(`@/synapps/locales/${locale}.json`);
      messages[locale] = module.default;

      // Adds the built-in Vuetify translations for this locale
      messages[locale].$vuetify = vuetifyLocales[locale];
    } catch (error) {
      // Handle the error (e.g., log it) if the import fails for a locale
      console.error(`Failed to load locale "${locale}":`, error);
    }
  }));

  return messages
}

export async function createI18n () {
  if (!i18nInstance) {
    i18nInstance = new VueI18n({
      locale: import.meta.env.VITE_I18N_LOCALE || 'en',
      fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
      messages: {
        ...await loadLocaleMessages()
      }
    }) 
  }

  return i18nInstance
}

export function getI18n() {
  if (!i18nInstance) {
    throw new Error('i18n instance has not been created yet. Please call createI18n() before calling getI18n().')
  }

  return i18nInstance
}
