import Vue from "vue";
import _ from "lodash";
import alertify from "alertify.js";

export default {
  namespaced: true,
  state: {
    overlays: {},
    baselayers: [],
    infoData: [],
    activeBaselayer: undefined,
    timeDimension: undefined,
    layerSettings: undefined,
    capabilities: {},
  },
  getters: {
    initActiveLayers: (state, getters, rootState) =>
      rootState.config.activeLayers,
    activeLayers: (state) =>
      _.map(_.filter(state.overlays, "options.active"), (x) => {
        return x.options.legend_name || x.options.name;
      }),
    allLayerIds: (state) => {
      return _.map(state.overlays, "_leaflet_id");
    },
    timeControlRequired: (state, getters) => {
      return !_.isEmpty(
        _.filter(
          state.overlays,
          (x) => x.options.active && !_.isEmpty(x.options.times)
        )
      );
    },
    activeLayersObjects: (state) =>
      _.map(_.filter(state.overlays, "options.active"), (x) => {
        return x;
      }),
  },
  mutations: {
    addOverlay: (state, { layer, group, options }) => {
      layer._group = group;
      layer.name = options.legend_name;

      _.assign(layer.options, options);
      Vue.set(state.overlays, layer._leaflet_id, layer);
    },
    addBaselayer: (state, layer) => state.baselayers.push(layer),
    setLayers: (state, layers) => {
      state.overlays = layers;
    },
    setLayerState: (state, { layer, value }) => {
      if (layer in state.overlays) {
        Vue.set(state.overlays[layer].options, "active", value);
      } else {
        console.info(`Layer ${layer} does not exist in overlays.`)
      }
    },
    setBaselayer: (state, layer) => {
      state.activeBaselayer = layer;
    },
    clearAllLayers: (state) => {
      state.overlays = {};
    },
    clearInfoData: (state) => {
      state.infoData = [];
    },
    addInfo: (state, info) => state.infoData.push(info),

    removeLayer: (state, id) => Vue.delete(state.overlays, id),

    setTimeDimension: (state, td) => (state.timeDimension = td),
    openLayerSettings: (state, layer) => (state.layerSettings = layer),
    closeLayerSettings: (state) => (state.layerSettings = undefined),
    updateLayer: (state, { id, ...extras }) => {
      let layer = state.overlays[id];
      _.forEach(extras, (v, k) => Vue.set(layer, k, v));

      Vue.set(state.overlays[layer._leaflet_id], layer._leaflet_id, layer);
    },
    setCapabilities: (state, { url, res }) =>
      Vue.set(state.capabilities, url, res),
  },
  actions: {
    setLayerState(
      { state, commit, rootState, dispatch },
      { layer: id, value }
    ) {
      if (value) {
        let layer = state.overlays[id];

        if ("opacityLayers" in rootState.config) {
          let changedOpacityObjects = rootState.config.opacityLayers.filter(
            (v) => v !== null
          );
          if (
            changedOpacityObjects.map((e) => e.id).includes(layer.options.id)
          ) {
            let i = changedOpacityObjects.find((e) => e.id == layer.options.id)
              .value;

            layer.options.opacity = i;
          }
        }

        rootState.map.addLayer(layer);
        if (layer.options.time_dimension) {
          let timelayers = _.filter(
            state.overlays,
            (x) => x.options.active && !_.isEmpty(x.options.times)
          );
          if (!_.isEmpty(timelayers));
          let notmatching = _.filter(
            timelayers,
            (x) =>
              !_.isEqual(
                _.map(x.options.times, (x) => x.toISOString()),
                _.map(layer.options.times, (x) => x.toISOString())
              )
          );
          if (!_.isEmpty(notmatching)) {
            alertify.log(
              `Disabling ${notmatching.length} layers as they have incompatible timestamps`
            );
            _.forEach(notmatching, (x) =>
              dispatch("setLayerState", {
                layer: x._leaflet_id,
                value: false,
              })
            );
          }
          rootState.map.timeDimension.setAvailableTimes(
            layer.options.times,
            "replace"
          );
        }
      } else if (id in state.overlays) {
        rootState.map.removeLayer(state.overlays[id]);
      } else {
        console.info(`Layer ${id} does not exist in overlays.`)
      }
      commit("setLayerState", { layer: id, value });
    },
    getCapabilities: async ({ state, commit }, { url, axios }) => {
      if (!state.capabilities[url]) {
        commit("setCapabilities", { url, res: axios.get(url) });
        let res = await state.capabilities[url];
        commit("setCapabilities", { url, res });
      }
      return Promise.resolve(state.capabilities[url]);
    },
  },
};
