export default [{
    path: "/multicriteria/:id",
    name: 'multicriteria.home',
    component: () => import("@/synapps/views/multicriteria/dashboard"),
    meta: {requiresAuth: true},
    children: [{
        path: "domain/",
        name: "multicriteria.domains",
        component: () => import("@/synapps/views/multicriteria/domain"),
        meta: {requiresAuth: true},
    }, {
        path: "score/",
        name: "multicriteria.scores",
        component: () => import("@/synapps/views/multicriteria/score"),
        meta: {requiresAuth: true},
    }, {
        path: "solution/",
        name: "multicriteria.solutions",
        component: () => import("@/synapps/views/multicriteria/solution"),
        meta: {requiresAuth: true},
    }],
}]
  