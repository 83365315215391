import jwt_decode from "jwt-decode";
import { getToken } from "../utils.js";
import Imdclogo from '@/shared/assets/img/IMDC_noname_transparent.png'

/**
 * Fetch branding info from the API.
 * @param {string} key - The key of the branding info to fetch
 * @returns {Promise<Response>} - The response from the server
**/
async function fetchBrandingInfo(key) {
    const BASE_URL = `${import.meta.env.VITE_CAS_URL}/api/branding_info`
    const response = await fetch(`${BASE_URL}/${key}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getToken()}`
        }
    })
    
    if (response.status === 404) {
        console.info(`Branding info ${key} not found`)
        return
    } else if (!response.ok) {
        console.info(`Failed to fetch branding info ${key}`)
        return
    }

    return response
}

/**
 * Apply the branding stylesheet to the document.
 * @param {Response} response 
 */
async function stylesheet(response) {
    if (stylesheet) {
        const style = document.createElement('style')
        style.id = 'branding_info'
        style.innerHTML = await response.text()
        document.head.appendChild(style)
    }
}

/**
 * Apply the logo to the top bar image.
 * @param {Response} response
 */
async function logo(response) {
    if (response) {
        let reader = new FileReader();
        reader.readAsDataURL(await response.blob());
        reader.onloadend = function() {
            document.getElementById('topBarLogo').src = reader.result;
        }
    } else {
        document.getElementById('topBarLogo').src = Imdclogo;
    }
}

/**
 * Apply the favicon to the document.
 * @param {Response} response 
 */
async function favicon(response) {
    if (response) {
        let reader = new FileReader();
        reader.readAsDataURL(await response.blob());
        reader.onloadend = function() {
            let link = document.querySelector("link[rel*='icon']")
            link.type = 'image/x-icon';
            link.href = reader.result;
        }
    }
}

/**
 * Apply the title to the top bar.
 */
async function title() {
    let data = jwt_decode(getToken()); 
    if (data && data.branding_info?.name) {
        let element = document.querySelector('#topBarTitleClientName')
        element.innerHTML = data.branding_info.name
    }
}

const BrandingInfo = {

    /**
     * Install the branding info plugin.
     * 
     * This plugin fetches branding info and adds the $applyBrandingInfo method to the Vue prototype.
     * @param {Vue} Vue 
     */
    install(Vue) {

        let stylesheetResponse = fetchBrandingInfo('stylesheet')
        let logoResponse = fetchBrandingInfo('logo')
        let faviconResponse = fetchBrandingInfo('favicon')

        /**
         * Apply the branding stylesheet, logo, and favicon to the document.
         * This function should be called after the app is mounted.
         */
        Vue.prototype.$applyBrandingInfo = async function() {

            [
                stylesheetResponse,
                logoResponse,
                faviconResponse
            ] = await Promise.all([
                stylesheetResponse,
                logoResponse,
                faviconResponse
            ])

            stylesheet(stylesheetResponse); 
            logo(logoResponse);
            favicon(faviconResponse);
            title();
        }
    }  
} 

export default BrandingInfo
