/**
 * Vue.js router configuration.
 * @typedef RouteConfig
 * @property {string} path
 * @property {string} name
 * @property {Object | Promise<Object>} component
 * @property {Object} meta
 * @property {boolean} props
 * @property {Array<RouteConfig>} children
 */

/**
 * @type Array<RouteConfig>
 */
export const routes = [{
    path: '/app/:id/threshold-analysis',
    name: 'threshold-analysis.home',
    component: () => import('@/synapps/threshold-analysis/home'),
    meta: {requiresAuth: true},
    children: [{
        path: 'location/:locationId/overview',
        name: 'threshold-analysis.overview',
        component: () => import('@/synapps/threshold-analysis/overview'),
        meta: {
            requiresAuth: true,
            iconName: 'mdi-owl',
        },
        props: true,
    }, {
        path: 'location/:locationId/actions',
        name: "threshold-analysis.actions",
        component: () => import('./actions/actions.vue'),
        props: true,
        meta: {
            requiresAuth: true,
            iconName: 'mdi-view-dashboard',
        },
    }, {
        path: 'location/:locationId/head-live',
        name: 'threshold-analysis.head-live',
        component: () => import('@/synapps/threshold-analysis/head-live'),
        meta: {
            requiresAuth: true,
            iconName: 'mdi-ruler',
            activator: location => location.isWorkSite,
        },
        props: true,
    }]
}]
