<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="template.name"
      :rules="textRules"
      :label="$t('meetnet.headers.name')"
      required
    ></v-text-field>

    <v-textarea v-model="template.description" :label="$t('description')" rows="3"></v-textarea>

    <div class="text-right">
      <v-btn :loading="loading" :disabled="!valid" color="success" class="mr-4" @click="save">
        {{ $t('common.save') }}
      </v-btn>
      <v-btn @click="cancel"> {{ $t('common.cancel') }} </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  name: "TemplateForm",
  props: {
    template: {
      type: Object,
      default: function() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
      textRules: [(v) => !!v || `${this.$t('a-value-is-required')}`],
    };
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    save() {
      if (this.validate()) {
        this.$emit("save", this.template);
      }
    },
    cancel() {
      this.reset();
      this.$emit("cancel");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
