import { waitForAuth } from "@/synapps/api";
import Vue from "vue";

import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import PortalVue from "portal-vue";
import Clipboard from "v-clipboard";
import asyncComputed from "vue-async-computed";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import '../shared/assets/style/main.scss';
import Keypress from "vue-keypress";

Vue.component("Keypress", Keypress);
Vue.use(Clipboard);
Vue.use(asyncComputed);
Vue.config.productionTip = false;
Vue.use(PortalVue);
Vue.use(Antd);

// plugins
import vuetify from "./plugins/vuetify";
import BrandingInfo from "./plugins/branding_info"; 

import VueProgressBar from "vue-progressbar"
Vue.use(VueProgressBar, {
  autofinish: false
})

export let state = {}

// set other core dependencies.
import "leaflet/dist/leaflet.css";
import "leaflet-timedimension/dist/leaflet.timedimension.control.min.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import L from "leaflet";
import "leaflet-timedimension";
import "leaflet-draw";
import "leaflet-defaulticon-compatibility";
import "@/synapps/plugins/L.tilelayer.wmts";

import * as esri from "esri-leaflet";
L.esri = esri;

// import * as esriVector from "esri-leaflet-vector";
// L.esri.Vector = esriVector;

import _ from "lodash";
import moment from "moment-timezone";
moment.locale("en-gb");
window.L = L;
import alertify from "alertify.js";
import { createI18n } from "../shared/plugins/i18n";

Vue.prototype.$L = L;
Vue.prototype.$_ = _;
Vue.prototype.$alertify = alertify;
Vue.prototype.$moment = moment;

import LeafletDrawPatch from "./plugins/leafletDrawPatch";
Vue.use(LeafletDrawPatch);

export const eventBus = new Vue();

waitForAuth().then(() => {

  Vue.use(BrandingInfo)

  createI18n().then((i18n) => {
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  })
});
