import atlasConfig from "./atlas/config.mjs";
import myRacewindConfig from "./my-racewind/config.mjs";

/**
 * Static configuration flags for a module.
 * @property {string} icon The icon associated to this module
 * @property {number} width The default width for this module to open in navigation drawer
 * TODO: KevinVR: would be cool to mixin this with the dynamic configuration somehow (or just move it all to dynamic config in CAS with sensible defaults, cfr Atlas tool's SPT config)...
 */
export class StaticModuleConfig {
  constructor({ icon, width } = {}) {
    this.icon = icon || 'mdi-rss';
    this.width = width || 290;
  }
}

/**
 * Static (hard-coded) configuration for all known map-modules.
 * @type {Map<string, StaticModuleConfig>}
 */
export const staticModuleConfigs = new Map([
  ["atlas", new StaticModuleConfig(atlasConfig)],
  ["myracewind", new StaticModuleConfig(myRacewindConfig)],
  [
    "config",
    new StaticModuleConfig({
      icon: "mdi-cog",
      width: 290,
    }),
  ],
  [
    "layercontrol",
    new StaticModuleConfig({
      icon: "mdi-layers-triple",
      width: 290,
    }),
  ],
  // The multicriteria module has been removed from the codebase at 2023-02-06
  // TODO: Kevin's message: if this code does not need to be revived within a few months please feel free to remove it entirely
  //   see also components/modules/multiCriteria.vue
  // [
  //   "multicriteria",
  //   new StaticModuleConfig({
  //     icon: "mdi-abacus",
  //     width: 290,
  //   }),
  // ],
  [
    "observations",
    new StaticModuleConfig({
      icon: "mdi-remote",
      width: 290,
    }),
  ],
  [
    "other",
    new StaticModuleConfig(),
  ],
  [
    "runs",
    new StaticModuleConfig({
      icon: "mdi-axis-y-arrow",
      width: 290,
    }),
  ],
  [
    "scenarios",
    new StaticModuleConfig({
      icon: "mdi-axis-y-arrow",
      width: 290,
    }),
  ],
  [
    "staticlayer",
    new StaticModuleConfig({
      icon: "mdi-cogs",
      width: 290,
    }),
  ],
  [
    "coastex",
    new StaticModuleConfig({
      icon: "mdi-chart-bar",
      width: 290,
    }),
  ],

]);
