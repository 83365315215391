export default [
  {
    path: "/ambition",
    name: "ambition",
    component: () => import("@/synapps/views/settings/ambition"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    redirect: '/accounts',
    component: () => import("@/synapps/views/settings/settingsDashboard"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "account",
        name: "accountSettings",
        component: () => import("@/synapps/views/settings/accountSettings"),
        meta: { requiresAuth: true },
      },
      {
        path: "organization",
        name: "organizationSettings",
        component: () => import("@/synapps/views/settings/meetnet/organizationSettings"),
        meta: { requiresAuth: true }
      },
      {
        path: "notification/",
        name: "emailNotificationSettings",
        component: () => import("@/synapps/views/settings/meetnet/emailNotificationSettings"),
        meta: { requiresAuth: true },
      },
      {
        path: "tasks/",
        name: "taskSettings",
        component: () => import("@/synapps/views/settings/meetnet/taskSettings"),
        meta: { requiresAuth: true },
      },
      {
        path: "organization/:orgID/network/:netID",
        name: "networkSettings",
        component: () => import("@/synapps/views/settings/meetnet/networkSettings"),
        meta: { requiresAuth: true },
      },
    ],
  },
];
