import { getCookie } from "@/synapps/utils";
import { notification } from "ant-design-vue";

/**
 * Sets request and response interceptors for default customized Axios instance to deal with JWT token (re)auth.
 * @param instance {axiosM.AxiosInstance} The axios instance to add interceptors for.
 * @param [meetnetUrl] {string} Base URL for meetnet to use. Falls back to session storage.
 */
export function addInterceptorsToAxiosInstance(instance, meetnetUrl) {
  const baseURL = meetnetUrl || localStorage.getItem("meetnetBaseURL");
  instance.defaults.baseURL = baseURL;
  instance.interceptors.request.use(
    (config) => {
      /* Meetnet Requests */
      if (
        config.url.includes(`${baseURL}/`) &&
        !config.url.includes(`/api-token-auth`)
      ) {
        config.headers["Authorization"] =
          "jwt " + localStorage.getItem("meetnetJWT");
      }
      /* Kustvisie Requests */
      if (config.url.includes(`/api/mca/`)) {
        config.headers["Authorization"] = "jwt " + localStorage.getItem("jwt");
      }

      // config.withCredentials = true;
      let csrf_cookie = getCookie("meetnet_csrftoken");
      if (csrf_cookie) {
        config.headers["X-CSRFTOKEN"] = csrf_cookie;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  //Response interceptor for Meetnet
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      // console.log("ERROR response intcpt ", originalRequest._retry);

      if (
        error.response &&
        error.response.status === 403 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const casJWT = localStorage.getItem("jwt");
        return instance({
          method: "POST",
          headers: { Authorization: "jwt " + casJWT },
          url: `${localStorage.getItem("meetnetBaseURL")}/api-token-auth`,
        })
          .then((response) => {
            localStorage.setItem("meetnetJWT", response.data.token);
            instance.defaults.headers.common["Authorization"] =
              "jwt " + response.data.token;
            return instance(originalRequest);
          })
          .catch((error) => {
            console.log("retry authenticateMeetnet error", error);
          });
      } else if (
        error.response &&
        error.response.status === 403 &&
        originalRequest._retry
      ) {
        originalRequest._retry = true;
        let errorMsg = "";
        if (error?.response?.data?.detail) {
          errorMsg = error.response.data.detail;
          errorMsg = errorMsg[0].toUpperCase() + errorMsg.slice(1);
        }
        notification.error({
          message: errorMsg,
        });
        return Promise.reject();
      } else {
        return Promise.reject(error);
      }
    }
  );
}
