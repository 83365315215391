import _ from "lodash";
import Vue from "vue";
import { scaleOrdinal, schemeCategory10 } from "d3";

export default {
  namespaced: true,
  state: {
    xUnits: undefined,
    elements: [],
    noPlotMsg: undefined,
    annotations: undefined,
    plotRange: [],
    plot: undefined,
    clickMode: "new",
    rangeLock: false,
    lastClickedElementSrc: undefined,
    menuValue: undefined,

    /**
     * Allows to set a vue component to be used as the hover component when pointing on a marker.
     * See tsPlotLayer.leaflet_popup_component()
     */
    hover_component: {
      key: null,
      name: null,
      props: null,
    },

    hover_component_key: null,
    hover_component_name: null,
    hover_component_props: null,
  },
  getters: {
    units: (state) => {
      return _.uniq(
        _.map(
          _.reverse(
            _.sortBy(state.elements, (x) => {
              return x.visible == true;
            })
          ),
          "units"
        )
      );
    },
    axes: (_state, getters) => {
      return _.map(getters.units, (un, idx) => ({
        side: idx >= 1 ? "right" : "left",
        // title: un == "m" ? "Level" : "",
        ticksuffix: un,
        zerolinewidth: 1,
        zerolinecolor: "#bdbdbd",
        automargin: true,
        // positions: idx >= 1 ? 1 - 0.15 * (idx - 1) : undefined
      }));
    },
    extraLayoutOpts: (state) => {
      if (!_.isEmpty(state.elements)) {
        return _.first(state.elements).extraLayoutOpts;
      }
    },
    plotlyPlot: (state, getters) => {
      if (_.isEmpty(state.elements)) {
        return undefined;
      }

      let plotObject = {
        data: _.map(
          state.elements,
          ({
            x,
            y,
            text,
            mode,
            hovertemplate,
            line,
            marker,
            units,
            fill,
            fillcolor,
            name,
            sensor,
            originalTimes,
            type,
            visible,
            connectgaps,
          }) => {
            let unIdx = _.findIndex(getters.units, (l) => l == units);
            return {
              x,
              y,
              text: text || "",
              connectgaps,
              name,
              sensor,
              originalTimes,
              type,
              fill,
              fillcolor,
              mode,
              hovertemplate: hovertemplate || `%{y:.2f} ${units}`,
              line,
              marker: marker || {},
              yaxis: unIdx >= 1 ? `y${unIdx + 1}` : "y",
              visible: !_.isUndefined(visible) ? visible : true,
            };
          }
        ),
        layout: {
          theme: "seaborn",
          annotations: state.annotations,
          showlegend: true,
          autosize: true,
          hovermode: "x unified",
          legend: { orientation: "h", font: { size: 11 } },
          bargap: state.bargap,
          margin: {
            l: 70,
            r: 70,
            b: 0,
            t: 25
          },
          // expand the different yaxes
          ..._.reduce(
            getters.axes,
            (result, ax, idx) => {
              if (idx >= 1) {
                ax.overlaying = "y";
                ax.hoverformat = ".2f";
                ax.anchor = "free";
                ax.position = 1 - 0.06 * (idx - 1);
              }

              result[`yaxis${idx >= 1 ? idx + 1 : ""}`] = {
                ...ax,
                fixedrange: state.extraLayoutOpts?.nozoom,
              };

              return result;
            },
            {}
          ),
          xaxis: {
            fixedrange: state.extralayoutOpts?.nozoom ? true : false,
            ticksuffix: state.xUnits == "time" ? "" : ` ${state.xUnits}`,
            domain: [0, 1 - Math.max(getters.units.length - 2, 0) * 0.06],
            ...getters.extraLayoutOpts?.xaxis,
          },
          ...getters.extraLayoutOpts,
        },
        options: {
          responsive: true,
          displayModeBar: false,
        },
      };

      const domain = Array.from({ length: state.elements.length }, (_, i) => i);
      const colors = scaleOrdinal(domain, schemeCategory10);

      let shapes = [];
      _.forEach(state.elements, (el, idx) => {
        if (el.thresholds) {
          try {
            _.forEach(el.thresholds, ({ name, value }) => {
              try {
                plotObject.data.push({
                  x: [el.x[1]],
                  y: [value + 0.05],
                  mode: "text",
                  text: [name],
                  showlegend: false,
                  textfont: { color: colors(idx) },
                });
              } catch (err) {
                console.log("There was an error while generating the plotting data", err)
              }
            });
          } catch (err) { 
            console.log("There was an error while generating the plotting data", err)
          }

          let unIdx = _.findIndex(getters.units, (l) => l == el.units);
          shapes.push(
            ..._.map(el.thresholds, (threshold) => {
              if (_.isObject(threshold)) {
                threshold = threshold.value;
              }
              return {
                type: "line",
                xref: "paper",
                x0: 0,
                y0: threshold,
                x1: 1,
                y1: threshold,
                line: {
                  color: colors(idx),
                  width: 2,
                  dash: "dot",
                },
                yref: unIdx >= 1 ? `y${unIdx + 1}` : "y",
              };
            })
          );
        }
      });

      _.forEach(state.elements, (el) => {
        if (el.rangeMarkers) {
          shapes.push(
            ..._.map(el.rangeMarkers, (rangeMarker) => ({
              type: "line",
              yref: "paper",
              y0: 0,
              x0: rangeMarker,
              y1: 1,
              x1: rangeMarker,
              line: {
                color: "grey",
                width: 2,
                dash: "dot",
              },
            }))
          );
        }
      });

      Vue.set(plotObject.layout, "shapes", _.flatten(shapes));

      return plotObject;
    },
    plot: (state, getters) => {
      if (state.noPlotMsg) {
        return state.noPlotMsg
      } else {
        if (!_.isEmpty(state.elements)) {
          return getters.plotlyPlot;
        } else {
          return undefined;
        }
      }
    },
    plotSrcs: (state) => {
      return _.map(state.elements, "src");
    },
    uniquePlotSrcs: (state) => {
      let val = _.map(state.elements, "src");
      let unique = _.uniq(val);
      return unique;
    },
    clickMode: (state) => {
      return state.clickMode;
    },
    inControl: (_state, getters) => {
      let controllers = _.uniqBy(
        _.map(getters.plotSrcs, (x) => _.join(_.take(_.split(x, ":"), 2), ":"))
      );
      return controllers.length == 1 ? _.first(controllers) : undefined;
    },
    noPlotMsg: (state) => {
      return state.noPlotMsg;
    },
    lastClickedElementSrc: (state) => {
      return state.lastClickedElementSrc;
    },
    menuValue: (state) => {
      return state.menuValue;
    },
  },
  mutations: {
    removeSourceFromElements: (state, source) => {
      state.elements = state.elements.filter((el) => el.src != source);
    },
    setActiveAxes: (state, value) => {
      state.activeAxes = value;
    },
    toggleRangeLock: (state) => (state.rangeLock = !state.rangeLock),
    setBargap: (state, bargap) => {
      state.bargap = bargap;
    },
    clearBargap: (state) => (state.bargap = NaN),
    addPlot: (state, elements) => {

      // remove previously plotted elements that are in the new elements to be plotted
      const sources = elements.map(element => element.src)
      state.elements = state.elements.filter(element => !sources.includes(element.src));

      state.lastClickedElementSrc = elements[0].src;

      const xUnits = elements[0].xUnits;
      if (state.xUnits === xUnits) {
        state.elements.push(...elements);
      } else {
        state.elements = elements;
        state.xUnits = xUnits;
      }
      state.noPlotMsg = undefined;
      state.clickMode = "new";
    },
    newPlot: (state, els) => {
      if (!_.isEmpty(els) && Array.isArray(els)) {
        let annotations = _.remove(els, "text");
        state.lastClickedElementSrc = els[0].src;
        state.elements = els;
        state.xUnits = els[0].xUnits;
        state.noPlotMsg = undefined;
        state.annotations = annotations;
      } else if (
        !_.isEmpty(els) &&
        !_.isEmpty(els.elements) &&
        !els.hasAnnotations
      ) {
        els = els.elements;
        state.lastClickedElementSrc = els[0].src;
        state.elements = els;
        state.xUnits = els[0].xUnits;
        state.noPlotMsg = undefined;
      }
    },
    setNoPlotMsg: (state, val) => state.noPlotMsg = val,
    clearPlot: (state) => {
      state.elements = [];
      state.xUnits = undefined;
      state.noPlotMsg = undefined;
      state.bargap = NaN;
    },
    setPlotRange: (state, val) => (state.plotRange = val),
    setClickMode: (state, val) => (state.clickMode = val),
    setMenuValue: (state, val) => (state.menuValue = val),
  },
  actions: {},
};
