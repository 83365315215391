import axios from "axios"

const state = {
  sensors: undefined,
  allSensors: undefined,
  sensorPermissions: [],
  sensorsLoading: false,
};

const getters = {
  meetnetConfig(state, getters, rootState) {
    return rootState.config?.meetnet;
  },
  sensors(state) {
    if (state.sensors) {
      state.sensors.sort(function(a, b) {
        var dateB = new Date(b.last_update);
        var dateA = new Date(a.last_update);
        if (dateB < dateA) {
          return -1;
        }
        if (dateB > dateA) {
          return 1;
        }
        return 0;
      });
      return state.sensors;
    } else {
      return [];
    }
  },
  allSensors(state) {
    if (state.allSensors) {
      state.allSensors.sort(function(a, b) {
        var dateB = new Date(b.last_update);
        var dateA = new Date(a.last_update);
        if (dateB < dateA) {
          return -1;
        }
        if (dateB > dateA) {
          return 1;
        }
        return 0;
      });
      return state.allSensors;
    } else {
      return [];
    }
  },
  sensorPermissions(state) {
    return state.sensorPermissions;
  },
  sensorsLoading(state) {
    return state.sensorsLoading;
  },
};

const mutations = {
  setSensors(state, payload) {
    state.sensors = payload;
  },
  setAllSensors(state, payload) {
    state.allSensors = payload;
  },
  setSensorPermissions(state, payload) {
    state.sensorPermissions = payload;
  },
  setSensorsLoading(state, value) {
    state.sensorsLoading = value;
  },
};

const actions = {
  setSensors(context, payload) {
    context.commit("setSensors", payload);
  },

  setAllSensors(context, payload) {
    context.commit("setAllSensors", payload);
  },

  setSensorsLoading(context, value) {
    context.commit("setSensorsLoading", value);
  },

  getSensor(context, pk) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/sensoren/sensor/${pk}/`,
    });
  },

  getSensorPermissions(context) {
    axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/sensoren/sensor/listPermissions/`,
    })
      .then((response) => {
        context.commit("setSensorPermissions", response.data);
      })
      .catch((error) => {
        console.log("error getSensorPermissions", error);
      });
  },

  getSensorInstallations(context, pk) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/sensoren/sensor/${pk}/get_sensor_locations/`,
    });
  },

  getSensors({ getters, commit }) {
    axios.get(
      `${getters.meetnetAPIURL}/sensoren/sensor/`
    ).then((response) => {
      if (response) {
        commit("setSensors", response.data);
        commit("setAllSensors", response.data);
        commit("setSensorsLoading", false);
      }
    })
    .catch((error) => {
      console.log("getSensors Error", error);
      commit("setSensorsLoading", false);
    });
  },

  searchSensors(context, query) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/sensoren/sensor/?search=${query}`,
    });
  },

  getUnlinkedSensors(context) {
    return axios({
      method: "GET",
      data: {},
      url: `${context.getters.meetnetAPIURL}/sensoren/sensor/get_unlinked_sensors/`,
    });
  },

  createMeasurementSensor(context, payload) {
    return axios({
      method: "POST",
      data: payload,
      url: `${context.getters.meetnetAPIURL}/sensoren/sensor/`,
    });
  },

  updateMeasurementSensor(context, payload) {
    return axios({
      method: "PATCH",
      data: payload,
      url: `${context.getters.meetnetAPIURL}/sensoren/sensor/${payload.serial_number}/`,
    });
  },
  getSupportedSensorFileTypes(context) {
    return axios.get(
      `${context.getters.meetnetAPIURL}/sensoren/supportedsensorfiletypes/`
    );
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
