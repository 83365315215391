import templateStore from "@/synapps/store/multicriteria/templateStore";
import analysisStore from "@/synapps/store/multicriteria/analysisStore";
import userInfoService from "./user-info-service.mjs";

const modules = {
  templateStore,
  analysisStore,
};

const state = {
  userInfo: null
};

const getters = {
  userInfo(state) {
    return state.userInfo
  },

  /**
   * Checks if the userinfo stored in this vuex store has the specified permission
   * @example
   * ...mapGetters(['hasPermission'])
   * this.hasPermission('foo')
   *
   */
  hasPermission(state) {
    return permission => {
      if(state.userInfo === null) {
        return false
      }
      return state.userInfo.hasPermission(permission)
    }
  }
};

const mutations = {};

const actions = {
  /**
   * Ensures that the user info is loaded.
   * Once loaded, it will be stored inside the store and served from cache on subsequent calls.
   * It is also accessible via getter.
   * @param context Vuex context
   * @returns {Promise<Object>} The loaded user info
   */
  async ensureUserInfo(context) {
    console.info('Ensuring MCA user info')
    if(context.state.userInfo === null) {
      context.state.userInfo = await userInfoService.fetchUserInfo()
    }
    return context.state.userInfo
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  modules,
};
