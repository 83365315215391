export default [
  {
    path: "/meetnet/:id",
    component: () => import("@/synapps/views/meetnet/meetnetDashboard"),
    meta: { requiresAuth: true },
    children: [
      // Site
      {
        path: "site/",
        name: "sites",
        component: () => import("@/synapps/components/meetnet/site/sites/sites-list-page.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "site/:siteID/edit",
        name: "editSite",
        component: () => import("@/synapps/views/meetnet/site/editSite"),
        meta: { requiresAuth: true },
      },
      {
        path: "site/:siteID/installation/new",
        name: "newSensorInstallation",
        component: () => import("@/synapps/views/meetnet/site/newSensorInstallation"),
        meta: { requiresAuth: true },
      },
      {
        path: "site/:siteID/installation/:installID/edit",
        name: "editSensorInstallation",
        component: () => import("@/synapps/views/meetnet/site/editSensorInstallation"),
        meta: { requiresAuth: true },
      },
      {
        path: "site/new",
        name: "createSite",
        component: () => import("@/synapps/views/meetnet/site/createSite"),
        meta: { requiresAuth: true },
      },
      {
        path: "site/:siteID",
        name: "site",
        component: () => import("@/synapps/views/meetnet/site/siteDetails"),
        meta: { requiresAuth: true },
      },
      {
        path: "site/:siteID/data",
        name: "siteData",
        params: true,
        component: () => import("@/synapps/views/meetnet/site/siteData"),
        meta: { requiresAuth: true },
      },
      {
        path: "site/:siteID/statistics",
        name: "siteStats",
        params: true,
        component: () => import("@/synapps/views/meetnet/site/siteStats"),
        meta: { requiresAuth: true },
      },
      // Sensor
      {
        path: "sensor",
        name: "sensors",
        component: () => import("@/synapps/views/meetnet/sensor/sensorDashboard"),
        meta: { requiresAuth: true },
      },
      {
        path: "sensor/new",
        name: "createSensor",
        component: () => import("@/synapps/views/meetnet/sensor/createSensor"),
        meta: { requiresAuth: true },
      },
      {
        path: "sensor/:sensorID",
        name: "sensor",
        component: () => import("@/synapps/views/meetnet/sensor/sensorDetails"),
        meta: { requiresAuth: true },
      },
      {
        path: "sensor/:sensorID/edit",
        name: "editSensor",
        component: () => import("@/synapps/views/meetnet/sensor/editSensor"),
        meta: { requiresAuth: true },
      },
      {
        path: "/",
        redirect: "site/",
        meta: { requiresAuth: true },
      },
    ],
  },
];
