<template>
  <div>
    <v-dialog v-model="shortcutsDialog" width="750">
      <v-card>
        <v-card-title
          >Keyboard Shorcuts reference
          <v-spacer></v-spacer>
          <v-btn icon x-small @click="shortcutsDialog = false"
            ><v-icon small>mdi mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-simple-table class="w-100 mx-auto py-2 my-2">
              <tbody>
                <tr>
                  <td>
                    <v-chip label small>shift + ?</v-chip>
                  </td>
                  <td>Open shortcuts reference</td>
                  <td>
                    <v-chip label small>b</v-chip>
                  </td>
                  <td>Toggle Baselayer</td>
                </tr>
                <tr>
                  <td>
                    <v-chip label small>a</v-chip>
                  </td>
                  <td>Open attribution panel</td>
                  <td>
                    <v-chip label small>1 - 9</v-chip>
                  </td>
                  <td>Switch modules on left navigation bar</td>
                </tr>
                <tr>
                  <td>
                    <v-chip label small>alt + h</v-chip>
                  </td>
                  <td>Go to App overview page</td>
                  <td>
                    <v-chip label small>alt + m</v-chip>
                  </td>
                  <td>Go to Map page</td>
                </tr>
                <tr>
                  <td>
                    <v-chip label small>alt + c</v-chip>
                  </td>
                  <td>Go to config page</td>
                  <td>
                    <v-chip label small
                      ><v-icon x-small>mdi mdi-arrow-left</v-icon
                      ><v-icon x-small>mdi mdi-arrow-right</v-icon></v-chip
                    >
                  </td>
                  <td>Go to previous / next feature</td>
                </tr>
                <tr>
                  <td>
                    <v-chip label small>d</v-chip>
                  </td>
                  <td>Download timeseries as CSV</td>
                  <td>
                    <v-chip label small>esc</v-chip>
                  </td>
                  <td>Close dialog or bottom panel</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-row>
        </v-card-text>
      </v-card></v-dialog
    >
    <Keypress
      key-event="keyup"
      :key-code="188"
      :modifiers="['shiftKey']"
      @success="shortcutsDialog = true"
      :preventDefault="true"
    ></Keypress>
    <Keypress
      key-event="keyup"
      :key-code="27"
      @success="shortcutsDialog = false"
    ></Keypress>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shortcutsDialog: false,
    };
  },
};
</script>