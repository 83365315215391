<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dense flat color="#e5e5e5">
          <v-btn small icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <!-- {{
              $t("multicriteria.headers.chapters")
            }} -->
            {{ solution.name }} - {{ template.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <p v-if="template.description">{{ template.description }}</p>
          <template-chapter :template="template" :solution="solution" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "TemplateWeightsDialog",
  components: {
    TemplateChapter: () =>
      import("@/synapps/components/multicriteria/chapter/templateChapter.vue"),
  },
  props: {
    template: {
      required: true,
    },
    weightsDialog: {
      type: Boolean,
      required: true,
    },
    solution: {
      required: true,
    },
  },

  data() {
    return {
      valid: true,
      textRules: [(v) => !!v || `${this.$t("a-value-is-required")}`],
    };
  },

  computed: {
    dialog: {
      get: function() {
        return this.weightsDialog;
      },
      set: function() {
        this.$emit("closeDialog");
      },
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    save() {
      if (this.validate()) {
        this.$emit("updateTemplate", this.template);
      }
    },
    cancel() {
      this.reset();
      this.$emit("cancel");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
