import axios from "axios"
import _ from 'lodash';

const state = {
  mca_templates: [],
  mca_templates_loading: false,
  mca_selected_template: {},
};

const getters = {
  mca_selected_template: (state) => {
    return state.mca_selected_template;
  },
  mca_templates: (state) => {
    return state.mca_templates;
  },
  mca_templates_loading: (state) => {
    return state.mca_templates_loading;
  },
};

const mutations = {
  set_mca_templates(state, payload) {
    state.mca_templates = payload;
  },
  set_mca_templates_loading(state, payload) {
    state.mca_templates_loading = payload;
  },
  set_mca_selected_template(state, payload) {
    state.mca_selected_template = payload;
  },
};

const actions = {
  mca_get_templates: ({ commit }) => {
    commit("set_mca_templates_loading", true);
    axios({
      method: "GET",
      data: {},
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}template/`,
    })
      .then((response) => {
        commit("set_mca_templates", response.data);
        commit("set_mca_templates_loading", false);
        commit("set_mca_selected_template", response.data[0]);
      })
      .catch((error) => {
        console.log("failed to get templates", error);
        commit("set_mca_templates_loading", false);
      });
  },
  mca_get_analysis_templates: ({ commit }, analysisID) => {
    return axios({
      method: "GET",
      data: {},
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}/template/?analysis=${analysisID}`,
    })
  },
  mca_create_template: ({}, payload) => {
    return axios({
      method: "POST",
      data: payload,
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}template/`,
    });
  },
  set_mca_selected_template: ({ commit }, payload) => {
    commit("set_mca_selected_template", payload);
  },
  mca_get_template_weights(context, templateID) {
    return axios({
      method: "GET",
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}template/${templateID}/weight/`,
      data: {},
    });
  },
  mca_add_template_weight(context, payload) {
    return axios({
      method: "POST",
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}weight/`,
      data: payload,
    });
  },
  mca_edit_template_weight(context, payload) {
    return axios({
      method: "PATCH",
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}weight/${payload.oid}/`,
      data: payload,
    });
  },
  mca_delete_template_weight(context, payload) {
    return axios({
      method: "DELETE",
      url: `${import.meta.env.VITE_KUSTVISIE_SERVER_URL}weight/${payload.oid}/`,
    });
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
